:root {
    --main-bg-color: #4caf50;
    --border-color: #ccc;
    --spacing-small: 10px;
    --spacing-medium: 20px;
}

.verticalLine {
    border-left: 2px solid var(--border-color);
    height: 40px;
    margin: 0 var(--spacing-medium);
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
}


.popup_content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
}

.button_group {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
}

.audio_list {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    /* Ensures the list takes full width */
}

.audio_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    /* Adjust the width as needed */
    padding: 10px;
    margin-bottom: 10px;
    /* Adjust the margin as needed */
    border: 1px solid #ccc;
    /* Optional: Add a border for better visual separation */
    border-radius: 5px;
    /* Optional: Add border-radius for rounded corners */
}

.audio_list div {
    display: flex;
    align-items: center;
    /* Vertical centering */
}


.icon_container {
    display: flex;
    align-items: center;
    gap: 10px;
    /* Adjust the gap as needed */
    justify-content: center;
}

.icon_container .icon {
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
}

.icon_container .icon:hover {
    transform: scale(0.95);
    animation: pulse 1s infinite;
}

audio {
    margin-left: auto;
    /* Push audio controls to the right */
}

@keyframes recordingPulse {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    50% {
        transform: scale(1.2);
        opacity: 0.7;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.recording {
    animation: recordingPulse 1s infinite;
}

.progress_bar {
    width: 0;
    height: 5px;
    background-color: #4caf50;
    transition: width 0.5s;
}

.recordingIndicator {

    background-color: red;
    border-radius: 50%;
    display: inline-block;
    font-size: 50px;
    margin: 20px;
    animation: pulse 1s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    50% {
        transform: scale(1.2);
        opacity: 0.7;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.verticalLine {
    border-left: 2px solid #ccc;
    /* Adjust color as needed */
    height: 40px;
    /* Adjust based on your UI */
    margin: 0 20px;
    /* Side margins */
}

@media (max-width: 768px) {
    .button_group {
        flex-direction: column;
        gap: 10px;
    }

    .audio_item {
        flex-direction: column;
        align-items: flex-start;
    }
}

.icon_container .icon:focus {
    outline: 2px solid var(--main-bg-color);
    outline-offset: 2px;
}

@media (min-width: 1200px) {
    .audio_list {
        max-width: 960px;
        /* Limit max width for better readability */
        margin: auto;
    }
}