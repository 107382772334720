/* NewNavbar.css */
.mobile-nav {
  position: fixed;
  left: -100%;
  top: 0;
  width: 70%;
  height: 100%;
  background-color: #ddd4d4;
  transition: left 0.3s ease;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.mobile-nav.open {
  left: 0;
}

.close-btn {
  position: absolute;
  top: 15px;
  right: 20px;
  background: none;
  border: none;
  font-size: 30px;
  cursor: pointer;
  color: #333;
}

.sidebar-content {
  padding: 20px;
}

.profile-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.username {
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
}

.account-settings {
  margin-top: 10px;
}

.nav-list {
  list-style: none;
  padding: 0;
}

.nav-list li {
  margin: 10px 0;
  border-bottom: #001305 1px solid;
  text-align: center;
}

.nav-list li a {
  color: #333;
  text-decoration: none;
  font-size: 16px;
}

.nav-list li a:hover {
  text-decoration:overline;
}

.username-dropdown {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 18px;
  justify-content: center;
}

.dropdown-icon {
  margin-left: 10px;
  font-size: 16px;
  transition: transform 0.3s ease;
}

.dropdown-icon.open {
  transform: rotate(180deg);
}

/* Admin section */
.admin-section {
  margin-top: 20px;
}

.admin-section a {
  color: #007bff;
  text-decoration: none;
}

.admin-section a:hover {
  text-decoration: underline;
}

.feedback-section a {
  color: #001305;
  text-decoration: none;
}

.feedback-section a:hover {
  text-decoration: underline;
}
/* Subitems dropdown styling */
.admin-menu {
  margin-top: 10px;
}

/* Login section */
.login-section {
  text-align: center;
}

/* Enable dropdown on hover for Admin and Profile dropdowns */
.nav-item.dropdown:hover > .dropdown-menu {
    display: block;
    margin-top: 0; /* Align the dropdown menu without extra margin */
  }
  
  /* Optional: Add transition for smoother dropdown appearance */
  .dropdown-menu {
    transition: all 0.3s ease;
  }
  
  /* Ensure the dropdown menu appears above other elements */
  .navbar-nav .dropdown-menu {
    z-index: 1000;
  }
  
  /* Align the dropdown menu to the right for the Profile dropdown */
  .profile-dropdown .dropdown-menu {
    right: 0;
    left: auto;
  }
  
  /* Additional styling for active dropdown items */
  .search_result_item.active,
  .admin-dropdown .dropdown-menu .active,
  .profile-dropdown .dropdown-menu .active {
    background-color: #f0f0f0;
  }
  
  /* Optional: Adjust the dropdown menu width */
  .dropdown-menu {
    min-width: 200px;
  }

  .search-bar-section{
    margin:auto;
  }
  
  /* Responsive adjustments (if needed) */
  @media (max-width: 768px) {
    .nav-item.dropdown:hover > .dropdown-menu {
      display: block; /* Disable hover on smaller screens */
    }
    
  }

  
  