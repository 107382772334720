.modal_container {
    text-align: center;
    
   
  }
  
  .modal {
    display: block;
    position: fixed;
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  }
  
  .modal_content {
    background-color: #fcf5f5;
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 2%;
    transform: translate(-50%, -50%);
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 600px; /* Maximum width of the modal */
    max-height: 75%;
    overflow: auto;
  }
  
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  .boldTd {
    font-weight: bold;
  }  .successAnimation {
    text-align: center;
    padding: 50px;
  }
  
  .successMessage i {
    color: green;
    font-size: 80px;
    margin-bottom: 20px;
  }
  
  .successMessage h2 {
    color: green;
    font-size: 30px;
  }