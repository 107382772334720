.modal_container {
  text-align: center;
}

.modal {
  display: block;
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
}

.modal_content {
  background-color: Semi-transparent(252, 245, 245, 0.95); /* Corrected background color */
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 10px;
  transform: translate(-50%, -50%);
  padding: 20px;
  border: 1px solid transparent;
  width: 80%;
  max-width: 50%; /* Maximum width of the modal */
  max-height: 100%;
  overflow: auto;
}


.image_container {
  position: relative;
  width: 100%;
  height: 100%;
}


.close {
  position: absolute;
  top: 30px; /* Adjust as needed for spacing */
  right: 30px; /* Adjust as needed for spacing */
  font-size: 35px; /* Adjust size */
  color: whitesmoke; /* Change color if needed */
  background-color: Semi-transparent; /* Optional: Add a background */
  padding: 5px; /* Optional: Spacing inside the background */
  cursor: pointer;
  z-index: 10; /* Ensure it appears above the image */ 

}


.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.boldTd {
  font-weight: bold;
}

.successAnimation {
  text-align: center;
  padding: 50px;
}

.successMessage i {
  color: green;
  font-size: 80px;
  margin-bottom: 20px;
}

.successMessage h2 {
  color: green;
  font-size: 30px;
}

.progress_bar_container {
  position: relative;
  width: 100%;
  height: 5px;
  background: #e0e0e0;
  margin-top: 16px;
  border-radius: 3px;
  overflow: hidden;
}

.progress_bar {
  height: 100%;
  background: #4caf50;
  width: 0;
  transition: width 50ms linear;
}

.subscribe_button {
  position: absolute; /* Make the button position relative to its closest positioned ancestor */
  bottom: 10px; /* Distance from the bottom edge */
  right: 10px; /* Distance from the right edge */
  border-radius: 20px;
  background-color: orange;
  color: white;
  font-weight: bold;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  font-size: 14px; /* Default button font size */
  padding: 10px 20px;
  animation: blink 2s infinite; /* Add blinking animation */

}
/* Define the blinking animation */
@keyframes blink {
  10%, 70% {
    opacity: 1; /* Fully visible */
  }
  50% {
    opacity: 0.7; /* Fully transparent */
  }
} 
.subscribe_button:hover {
  background-color: darkorange;
}

/* Media Queries */

/* Extra Small Devices (Phones < 375px) */
@media (max-width: 374px) {
  .subscribe_button {
    font-size: 8px;
    padding: 5px 5px;
    bottom: 25px;
    right: 25px;
  }
  .close {
    top: 20px; /* Adjust as needed for spacing */
    right: 20px; /* Adjust as needed for spacing */
    font-size: 25px; /* Adjust size */
     
  }
  .modal_content{
max-width: 100%;
  }
}

/* Small Devices (Phones, 375px to 576px) */
@media (min-width: 375px) and (max-width: 576px) {
  .subscribe_button {
    font-size: 10px;
    padding: 5px 5px;
    bottom: 25px;
    right: 25px;
  }
  .close {
    top: 20px; /* Adjust as needed for spacing */
    right: 20px; /* Adjust as needed for spacing */
    font-size: 25px; /* Adjust size */
     
  }
  .modal_content{
    max-width: 100%;
      }
}

/* Medium Devices (Tablets, 576px to 768px) */
@media (min-width: 577px) and (max-width: 768px) {
  .subscribe_button {
    font-size: 14px;
    padding: 12px 20px;
    bottom: 25px;
    right: 25px;
  }
  .close {
    top: 20px; /* Adjust as needed for spacing */
    right: 20px; /* Adjust as needed for spacing */
    font-size: 30px; /* Adjust size */
     
  }
}

/* Large Devices (Tablets to Laptops, 768px to 1024px) */
@media (min-width: 769px) and (max-width: 920px) {
  .subscribe_button {
    font-size: 20px;
    padding: 14px 22px;
    bottom: 35px;
    right: 35px;
  }
  .close {
    top: 30px; /* Adjust as needed for spacing */
    right: 30px; /* Adjust as needed for spacing */
    font-size: 30px; /* Adjust size */
     
  }
}

/* Large Devices (Tablets to Laptops, 768px to 1024px) */
@media (min-width: 921px) and (max-width: 1024px) {
  .subscribe_button {
    font-size: 25px;
    padding: 14px 22px;
    bottom: 40px;
    right: 40px;
  }
  .close {
    top: 30px; /* Adjust as needed for spacing */
    right: 30px; /* Adjust as needed for spacing */
    font-size: 35px; /* Adjust size */
     
  }
}

/* Extra Large Devices (Laptops to Desktops, >1024px) */
@media (min-width: 1025px) {
  .subscribe_button {
    font-size: 25px;
    padding: 16px 24px;
    bottom: 50px;
    right: 50px;
  }
  .close {
    top: 30px; /* Adjust as needed for spacing */
    right: 30px; /* Adjust as needed for spacing */
    font-size: 35px; /* Adjust size */
     
  }
}
