
.question_container {
    margin: 20px;
  }
  
  .question {
    margin-bottom: 10px;
  }
  
  .option {
    padding: 10px;
    margin: 5px 0;
    background-color: #f7f7f7;
    border: 1px solid #ddd;
    cursor: pointer;
    border-radius: 10px;
  }
  
  .option:hover {
    background-color: #06b4db;
  }
  
  .correct {
    background-color: #144c21;
    color: white;
    border-color: #c3e6cb;
  }
  
  .incorrect {
    background-color: #ef7983;
    color: white;
    border-color: #f8273c;
  }
  