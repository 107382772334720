

.board {
  position: relative;
  margin: 20px auto;
  border-radius: 10px;
  border: 1px solid gray;
  height: auto;
  background: linear-gradient(rgba(238, 185, 185, 0.11), rgb(173, 152, 135));
  display: flex;
  flex-direction: column;
  align-items: center;
}

.glow::before, .glow::after {
  content: '';
  position: absolute;
  top: -4px;
  left: -4px;
  border-radius: 11px;
  background: linear-gradient(45deg, #e3c7ee, #FFC0CB, #D4AF37, #969072, #fdfdc7, #C0C0C0);
  background-size: 400%;
  width: calc(100% + 5px);
  height: calc(100% + 6px);
  z-index: -1;
  animation: animate 25s linear infinite;
}

.avatar {
  position: relative;
  margin-top: -65px;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  z-index: 1;
  border: 1px solid rgb(95, 95, 95);
  border-style: outset;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profileimg:hover {
  content: url('https://i.pinimg.com/736x/cf/34/f0/cf34f0af5496bf6eadda07c8042b9279.jpg');
}

.imgbtn {
  position: relative;
  color: #ffffff;
  border: none;
  outline: none;
  font-size: 18px;
  margin: 10px 0;
  background: none;
  padding: 5px;
  transition: 1s smooth;
}

hr {
  width: 80%;
  background: linear-gradient(90deg, #e3c7ee, #FFC0CB, #D4AF37, #FFD700, #fdfdc7, #C0C0C0);
  border: none;
  height: 2px;
}

.userName {
  flex-grow: 1;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  background: linear-gradient(90deg, #e3c7ee, #FFC0CB, #D4AF37, #FFD700, #fdfdc7, #C0C0C0);
  background-size: 200%;
  margin-top: 10px;
  margin-bottom: 10px;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: background-pan 16s linear infinite;
}

.details {
  margin: 20px 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.imgbtn span {
  font-size: 15px;
}

.label {
  color: whitesmoke;
  font-weight: 500;
  font-size: 1rem;
}

.profileimg {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  border: 1px solid grey;
  border-style: outset;
  cursor: pointer;
}

.shine::before, .shine::after {
  content: '';
  position: absolute;
  top: -1px;
  left: 3px;
  border-radius: 50%;
  background: linear-gradient(45deg, #e3c7ee, #FFC0CB, #D4AF37, #FFD700, #fdfdc7, #C0C0C0);
  background-size: 400%;
  width: calc(130px + 5px);
  height: calc(130px + 5px);
  z-index: -2;
  margin: -1.3px 0 0 -5.3px;
  animation: animate 25s linear infinite;
}

@keyframes animate {
  0% {
    background-position: -400% 0;
  }
  50% {
    background-position: 0 0;
  }
  100% {
    background-position: 400% 0;
  }
}

@keyframes background-pan {
  from {
    background-position: -200% center;
  }
  to {
    background-position: -400% center;
  }
}

.glow::after {
  filter: blur(20px);
}

.shine::after {
  filter: blur(10px);
}

.imgbtn:hover {
  color: #e4b73d;
  font-size: 22px;
}

/* Wrapper for the phone input and country selector */
.phone-input-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

/* Adjust the PhoneInput component to align properly */
.phone-input-custom {
  display: flex; /* Ensures both elements align in one line */
  align-items: center;
  flex: 1; /* Ensures the input field grows to fill the remaining space */
}

/* Target the country selector to keep it in line */
.phone-input-custom .PhoneInputCountry {
  margin-right: 10px; /* Add spacing between country selector and phone input */
  display: flex;
  align-items: center;
}

/* Target the actual phone input field */
.phone-input-custom .PhoneInputInput {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}


/* Mobile Styles */
@media (max-width: 768px) {

  body{
    overflow-y: scroll;
  }
  .board {
    width: 95%;
    min-width: 320px;
    margin: 20px auto;
    
  }

  .phone-input-custom .PhoneInputInput {
    width: 100%; /* Make sure it takes the full width on smaller screens */
    font-size: 14px;
    padding: 8px;
  }

  .userName {
    font-size: 20px;
  }

  .avatar {
    margin-top: -55px;
    width: 100px;
    height: 100px;
  }

  
.profileimg {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 1px solid grey;
  border-style: outset;
  cursor: pointer;
}

.shine::before, .shine::after {
  content: '';
  position: absolute;
  top: -18px;
  left: -12.5px;
  border-radius: 50%;
  background: linear-gradient(45deg, #e3c7ee, #FFC0CB, #D4AF37, #FFD700, #fdfdc7, #C0C0C0);
  background-size: 400%;
  width: calc(130px + 5px);
  height: calc(130px + 5px);
  z-index: -20;
  margin: 1.3px 0 0 -5.3px;
  animation: animate 25s linear infinite;

}

  .imgbtn {
    font-size: 16px;
    margin: 10px 0;
  }

  .details {
    margin: 10px;
    width: 90%;
  }

  .label {
    font-size: 0.9rem;
  }
}
