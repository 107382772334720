/* Score.module.css */

.parentDiv {
    display: flex;
    justify-content: space-around;
    margin: 20px 0;
  }
  
  .childDiv {
    font-size: 40px;
    font-weight: bold;
    flex: 1;
    padding: 20px;
    margin: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 10px;
    background: #f0f8ff; /* Light blue background */
    transition: background 0.3s ease;
  }
  
  .childDiv:hover {
    background: #e0f0ff; /* Slightly darker blue on hover */
  }
  
  .lineContainer {
    position: relative;
    width: 100%;
    height: 15px;
    border-bottom: 2px solid black;
    margin-bottom: 20px;
  }
  
  .score {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background: white;
    padding: 0 10px;
    font-size: 20px;
    font-weight: bold;
  }
  
  .dictionary {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin: 20px 0;
    height: 380px;
    overflow: scroll;
    
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  
  scrollbar-width: none;
  }
  
  .dictionaryItem {
    margin: 10px 0;
  }
  
  /* .correct {
    color: green;
    cursor: pointer;
    text-decoration: underline;
    font-weight: bold;
  }
  
  .incorrect {
    color: orange;
    font-weight: bold;
  }
   */
  .saveButtonContainer {
    width: 100%;
    text-align: center;
    margin-top: 20px;
  }
  
  .saveButton {
    padding: 10px 20px;
    background-color: dodgerblue;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 16px;
  }
  
  .saveButton:hover {
    background-color: #007bff;
  }
  
  .error {
    color: red;
    margin-top: 5px;
    font-size: 14px;
    text-align: center;
    transition: color 0.3s ease;
  }
  
  @media (max-width: 768px) {
    .parentDiv {
      flex-direction: column;
      align-items: center; /* Center children on mobile */
      
    }
  
    .childDiv {
      font-size: 30px;
      padding: 15px;
      width: 100%; /* Ensure full width */
      margin: 5px 0; /* Adjust margin for vertical spacing */
    }
  
    .score {
      font-size: 18px;
    }
  
    .saveButton {
      font-size: 14px;
      padding: 8px 16px;
    }
  }
  
  @media (max-width: 480px) {
    .childDiv {
      font-size: 24px;
      padding: 10px;
    }
  
    .score {
      font-size: 16px;
    }
  .saveButtonContainer{
    margin-bottom: 30px !important;
  }
    .saveButton {
      font-size: 14px; /* Slightly larger for better tap target */
      /* padding: 10px 10px; Slightly larger for better tap target */
      height: 40px;
      width: 160px;
    
    }
  }
  

  .dictionaryTable {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .dictionaryTable th,
  .dictionaryTable td {
    padding: 10px;
    border: 1px solid #ccc;
    text-align: center;
  }
  
  .correct {    cursor: pointer;
text-decoration: underline;
    color: green; /* Green for correct user's answer */
    font-weight: bold;
  }
  
  .incorrect {    cursor: pointer;
    font-weight: bold;
    text-decoration: underline;

    color: orange; /* Red for incorrect user's answer */
  }
  
  .correctActual {    cursor: pointer;
    text-decoration: underline;

    color: green; /* Green for actual answer when it matches the user's answer */
    font-weight: bold;
  }
  
  .incorrectActual {    cursor: pointer;
    font-weight: bold;
    text-decoration: underline;

    color: green; /* Orange for actual answer when it doesn't match the user's answer */
  }
  
  
  