.signupContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  max-height: 75%;
  overflow: auto;
}


/* Mobile Number Input Styling */
.phone-input {
  width: 100%;
  height: 45px;
  border-radius: 5px;
  border: 1px solid #ced4da;
  padding-left: 12px;
  font-size: 16px;
}

.phone-input:focus {
  border-color: #80bdff;
  outline: none;
  box-shadow: 0 0 5px rgba(128, 189, 255, 0.5);
}

@media (max-width: 768px) {
  .phone-input {
    width: 100%;
    font-size: 14px;
  }
}


/* Error message styling */
.alert-danger {
  font-size: 0.9rem;
  margin-top: 5px;
}

/* Adjust form field margins */
.form-group {
  margin-bottom: 15px;
}

.cardSignup {
  width: 100%;
  padding-top: 0% !important;
  /* margin-top: 0%;  */
  background: linear-gradient(rgba(243, 231, 231, 0.11), rgb(173, 152, 135));
  /* background: linear-gradient(rgb(4, 188, 234), rgb(6, 242, 132)); */
  border-radius: 10px;
  color: white;
  box-shadow: 0 0 15px rgba(126, 125, 125, 0.932);
  animation: fadeInUp 0.5s ease-in-out;
}

.cardTitle {
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 1.5rem;
}

.btnPrimary {
  background-color: #007bff;
  border: none;
  transition: background-color 0.3s ease;
}

.btnPrimary:hover {
  background-color: #0056b3;
}

.formGroup {
  margin-bottom: 1rem;
}

.alert {
  margin-top: 1rem;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
