/* SearchBar.module.css */

/* Container for the entire search bar */
.search_bar {
  position: relative;
  width: 100%;
  
  margin: 5px auto; /* Center the search bar with top and bottom margin */
}

/* Container for the input and clear button */
.input_container {
  position: relative;
 
}

/* Pill-shaped search input */
.search_input {
  padding: 8px 40px 8px 16px; /* Top, Right, Bottom, Left */
  border: 1px solid #ccc;
  border-radius: 9999px; /* Creates a pill shape */
  font-size: 16px;
  outline: none;
  transition: border-color 0.3s;
  box-sizing: border-box; /* Includes padding within the width */
}

.search_input:focus {
  border-color: #007BFF; /* Change border color on focus */
}

/* Circular clear button */
.clear_button {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #999;
  padding: 4px;
  border-radius: 50%; /* Makes the button circular */
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.clear_button:hover,
.clear_button:focus {
  color: #333;
  background-color: rgba(0, 0, 0, 0.05); /* Optional: subtle background on hover */
}

/* Search results dropdown */
.search_results {
  list-style: none;
  padding: 0;
  margin: 4px 0 0 0;
  border: 1px solid #ccc;
  border-top: none;
  max-height: 200px;
  overflow-y: auto;
  background-color: #fff;
  position: absolute;
  width: 100%;
  z-index: 1000;
  border-radius: 0 0 8px 8px; /* Rounded corners at the bottom */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

/* Individual search result items */
.search_result_item {
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.search_result_item:hover,
.search_result_item:focus,
.search_result_item.active {
  background-color: #f0f0f0;
}

/* Loading and error messages */
.loading,
.no_results,
.error {
  margin-top: 8px;
  font-size: 14px;
  color: #666;
}

.error {
  color: red;
}

/* Highlight matching text */
.highlight {
  font-weight: bold;
  background-color: yellow;
}

/* Spinner for loading state */
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border-left-color: #007BFF;
  animation: spin 1s linear infinite;
  display: inline-block;
  margin-right: 8px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Responsive Design: Adjust styles for smaller screens */
@media (max-width: 600px) {
  .search_bar {
    max-width: 100%;
  }

  .search_input {
    width: 190px;
    font-size: 18px;
    padding: 10px 44px 10px 20px; /* Increased padding for better touch targets */
  }

  .clear_button {
    width: 28px;
    height: 28px;
    font-size: 20px;
    right: 16px;
  }

  .search_result_item {
    padding: 12px 20px;
    font-size: 16px;
  }
}
