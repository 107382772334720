/* Container */
.container-flex {
  width: 60% !important;
  perspective: 1000px;
  margin: 0 auto;
  padding: 20px;
}

/* Card Container */
.card-container {
  background: linear-gradient(rgba(243, 231, 231, 0.11), rgb(173, 152, 135));
  border-radius: 10px;
  padding: 20px;
  color: white;
  max-height: 75%;
  overflow: auto;
  box-shadow: 0 0 15px rgba(126, 125, 125, 0.932);
  animation: fadeInUp 0.5s ease-in-out;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); Added shadow for depth */
}

/* Card Title */
.cardTitle {
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  color: #fcf8f8;
  margin-bottom: 1.5rem;
  font-family: 'Roboto', sans-serif; /* Consistent font */
}

/* Profile Image */
.profile-img-card {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 2px solid #fff; /* Added border for a cleaner look */
}

/* Buttons */
.btn-primary {
  background-color: #a9c9ec;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.2s ease; /* Smoother transitions */
}

.btn-primary:hover {
  background-color: #0056b3;
  transform: scale(1.05); /* Slight zoom effect */
}

/* Form Group Spacing */
.form-group {
  margin-bottom: 1.5rem;
  font-family: 'Open Sans', sans-serif;
}

/* Alerts */
.alert {
  margin-top: 1rem;
}

/* Flip Card Animation */
.flip-card {
  width: 100%;
  transform-style: preserve-3d;
  transition: transform 0.6s ease-in-out; /* Smoother flip */
}

.flip-card.flipped {
  transform: rotateY(180deg);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15); /* Added shadow when flipped */
}

.card-container {
  backface-visibility: hidden;
}

.card-signup {
  transform: rotateY(180deg);
  backface-visibility: hidden;
}
