/* Add styles for responsiveness */

@media (max-width: 768px) {
  /* Make table scrollable horizontally on smaller screens */
  .table-responsive {
    overflow-x: auto;  /* Enable horizontal scrolling */
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
  }

  .feedback-container {
    padding: 15px;
  }

  /* Stack columns on top of each other on small screens */
  .col-md-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.modal-dialog {
  width: 100%;
  max-width: 500px;
  margin: 20px auto;
}

/* AdminFeedback.css */

.table-container {
  max-height: 500px; /* Adjust the height as needed */
  overflow-y: auto;
  /* Optional: Add padding or other styles as needed */
}

/* Optional: Make table headers sticky */
.table-container thead th {
  position: sticky;
  top: 0;
  background-color: #f8f9fa; /* Match your table header background */
}

/* AdminFeedback.css */

.table-container {
  max-height: 500px; /* Adjust as needed */
  overflow-y: auto;
  margin-bottom: 20px; /* Optional: space below the table */
}

/* Sticky table headers */
.table-container thead th {
  position: sticky;
  top: 0;
  background-color: #f8f9fa; /* Bootstrap's table header color */
}

/* Optional: Customize scrollbar for Webkit browsers */
.table-container::-webkit-scrollbar {
  width: 8px;
}

.table-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.table-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.table-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Modal Backdrop (if not using Bootstrap's built-in backdrop) */
.modal-backdrop-custom {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1040; /* Below the modal z-index */
}

/* Additional Styling for Modal */
.modal-content {
  width: 100%;
  max-width: 600px; /* Adjust modal width as needed */
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close.btn {
  background: none;
  border: none;
  font-size: 1.5rem;
  line-height: 1;
}

