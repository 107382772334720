@keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.2);
      opacity: 0.7;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  .recording {
    animation: pulse 1s infinite;
    cursor: pointer;
    font-size: 24px;
    color: red;
  }
  
  .stoprecording {
    cursor: pointer;
    font-size: 24px;
    color: green;
  }

.importfile {
    cursor: pointer;
    font-size: 50px;
    color: green;
    cursor: pointer;
    size: 60;
}
