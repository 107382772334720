#hero {
  width: 100%;
  height: 100vh;
  font-family: Ubuntu, sans-serif;
}

.main-container {
  margin: auto;
  padding: 30px;
  width: 960;
  height: 100vh;
  overflow-y: scroll; /* Adds vertical scrolling */
  scrollbar-width: none; /* For Firefox, hides the scrollbar */
  -ms-overflow-style: none; /* For Internet Explorer and Edge, hides the scrollbar */
}

.main-container::-webkit-scrollbar {
  display: none;
}

@media (minwidth: "768px") {
  .main-container {
    width: 380px;
    overflow-y: scroll; /* Adds vertical scrolling */
    scrollbar-width: none; /* For Firefox, hides the scrollbar */
    -ms-overflow-style: none; /* For Internet Explorer and Edge, hides the scrollbar */
  }
}

.quiz-heading {
  text-align: center;
  color: #0551a8;
}

.dark-button {
  width: 100%;
  color: white;
  background: #0551a8;
  border-style: none;
  padding: 12px 0;
  border-radius: 0;
}

#player {
  margin-top: 30px;
  color: #fff;
  text-align: center;
}

.player-enclosure {
  margin: 30px;
}

.small-icon {
  color: #fff;
  font-size: 60px;
  cursor: pointer;
}

.big-icon {
  font-size: 90px;
  color: #fff;
  margin-left: 60px;
  margin-right: 60px;
  cursor: pointer;
}

/* #player-bg {
  background: url("../../assets/img/aab.png") center / cover no-repeat;
  padding: 30px;
  margin-top:20px;
  margin-bottom: 5px;
} */

/* #controls {
  width: 80%;
  margin: auto;
} */

/* #answer-inputs {
  margin-bottom: 30px;
  justify-content:center;
  width: 100%;
} */

.submit-answer-icon {
  font-size: 32px;
  margin-left: -40px;
  color: #fff;
  padding: 1px;
  cursor: pointer;
  justify-content: end;
}

/* #input-answer {
  border-radius: 26px;
  text-align: left;
  padding-left: 20px;
  background-color: transparent;
  border: 2px solid #fff;
  color: #fff;
} */

.hints-row {
  margin-top: 30px;
  text-align: left;
  margin: 30px;
}

.hints-row p {
  margin-bottom: 0;
  margin-left: -13px;
  text-transform: uppercase;
  margin-right: 20px;
  font-size: 14px;
}

.hint-box-2 {
  border: 1px solid #0db7f0;
  height: 120px;
  border-radius: 12px;
  margin-left: 3px;
}

.hint-box-1 {
  border: 1px solid #0db7f0;
  height: 120px;
  border-radius: 12px;
  margin-right: 3px;
  padding: 5px 20px;
}

.light-button {
  width: 100%;
  color: #fff;
  background-color: #0db7f0;
  border: none;
  padding: 12px 0;
  border-radius: 0;
}

.hint-type {
  color: #fff;
  font-size: 13px;
}

.hint-content-top-icons {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
}

.hint-content-hint {
  width: 100%;
  margin: 10px;
  background-color: transparent;
  border: none;
  color: #fff;
}

/* #input-answer::placeholder {
  color: whitesmoke;
} */

.navbar {
  background: #afa494;
}

.nav-link {
  color: white;
}

.navbar-brand {
  color: white;
}

#login-btn {
  border-style: none;
  background: #d67534;
}

#global-search {
  border-radius: 24px;
  border-style: none;
  padding: 4px 10px;
}

#categories {
  width: 685px;
  height: 80vh;
  background: #e5ebdf;
  overflow-x: hidden;
  scrollbar-width: none; /* Firefox */

  overflow-x: hidden; /* Hide horizontal scrollbar */
  overflow-y: auto; /* Ensure vertical scrollbar appears when content overflows */
  scrollbar-width: auto; /* Default width for Firefox */
}

.first-last {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.search-ctrl {
  border-radius: 20px;
  padding-left: 10px;
}

@media (min-width: 768px) {
  .first-last {
    flex-direction: row;
    justify-content: space-between;
  }
}

.packs {
  margin: 30px 0px;
}

.theme-button {
  background: #d67534;
  border-radius: 20px;
  border-style: none;
  width: 100px;
  color: #fff;
  max-height: 30px;
}

.theme-button:hover {
  background-color: #afa494;
}

.pack-details {
  display: flex;
  border-radius: 10px;
  border: 1px solid #c1c1c1;
  background: #fff;
}

.pack-image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-left: -20px;
  margin-top: -20px;
  border: 4px solid #fff;
}

.pack-info {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;
}

.pack-info p {
  color: gray;
  font-size: 14px;
  margin: 1px 0px;
}

.pack-row {
  margin-bottom: 10px;
}

#bottom-buttons {
  margin: 0px auto;
  text-align: center;
  color: #fff;
  align-items: center;
}

.bottom-big-box {
  width: 20px;
  height: 20px;
  text-align: center;
  background-color: #fff;
  flex-direction: column;
  padding: 0;
  border: 1px solid #0551a8;
}

.bottom-big-box-c {
  width: 150px;
  height: 40px;
  text-align: center;
  background-color: #fff;
  flex-direction: column;
  padding: 0;
  border: 1px solid #0551a8;
}

.bottom-big-box-d {
  width: 150px;
  height: 40px;
  background-color: #fff;
  flex-direction: column;
  padding: 0;
  border: 1px solid #0551a8;
}

.bottom-big-box-m {
  width: 40px;
  height: 40px;
  text-align: center;
  background-color: #fff;
  padding: 0;
  border: 1px solid #0551a8;
}

.play-stop {
  /* display: flex;
  justify-content: space-between; */
  color: #1c2e77;
  padding: 0 10px;
  align-items: center;
}

.home-button {
  color: #fff;
  font-size: 20px;
  margin-top: 10px;
}

#question-image {
  width: 120px;
  height: 120px;
  margin: 1px;
  border-radius: 50%;
}
.big-icon {
  cursor: pointer;
  margin-top: -90px;
  z-index: 1000;
  margin-left: 90px;
  height: 80px;
  width: 80px;
  opacity: 0.8;
}
.lm10 {
  margin-left: 10px;
  margin-top: 2px;
}

/* .item-align {
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
} */



.category-scroll-container {
  display: flex;
  overflow-x: auto; /* Enable horizontal scrolling */
  scroll-behavior: smooth; /* Smooth scrolling */
  gap: 10px; /* Add spacing between items */
  padding: 10px; /* Padding around the items */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
}

.category-scroll-container::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari, and Edge */
}

.item-align {
  flex: 0 0 300px; /* Fixed width for each item */
   /* border: 1px solid #ddd; Optional border */
  border-radius: 10px; /* Rounded corners */
  padding: 10px; /* Padding inside each card */
   /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);Optional shadow */
}
.cust_input::placeholder {
  color: rgba(255, 255, 255, 0.3); /* Light white placeholder color */
}

/* Small devices (max-width: 576px) - 1 column (full width) */
@media (max-width: 350px) {
  .item-align {
    width: 100%; /* Full width for small screens */
  }
  .pack-details {
    height: 130px;
  }
  .sub-buttons{
    width: 30px;
  }
  .hide-on-mobile {
    display: none;
  }
  .show-on-mobile {
    display: table-cell;
  }

}

/* Small devices (max-width: 576px) - 1 column (full width) */
@media (max-width: 576px) {
  .item-align {
    width: 100%; /* Full width for small screens */
  }
  .pack-details {
    height: 130px;
  }
  .sub-buttons{
    width: 30px;
  }
  .hide-on-mobile {
    display: none;
  }
  .show-on-mobile {
    display: table-cell;
  }

}

/* Medium devices (min-width: 577px and max-width: 768px) - 2 columns */
@media (min-width: 577px) and (max-width: 768px) {
  .item-align {
    width: 100%; /* Full width for small screens */
  }
  .pack-details {
    height: 130px;
  }


  .hide-on-mobile {
    display: none;
  }
  .show-on-mobile {
    display: table-cell;
  }

}
/* Large devices (min-width: 769px) - 3 columns */
@media (min-width: 769px) {
  .item-align {
    width: 50%; /* Full width for small screens */
  }
  .pack-details {
    height: 150px;
  }
  .show-on-mobile {
    display: none;
  }
  .hide-on-mobile {
    display: table-cell;
  }
  
  .show-hide-mobile {
    display: none;  
  }
}

/* Medium devices (min-width: 768px and max-width: 991px) - 2 columns */
@media (min-width: 768px) and (max-width: 991px) {
  .item-align {
    width: 50%; /* 3 columns */
  }
  .pack-details {
    height: 150px;
  }
}

/* Large devices (min-width: 992px and max-width: 1199px) - 3 columns */
@media (min-width: 992px) and (max-width: 1199px) {
  .item-align {
    width: 50%; /* Full width for small screens */
  }
  .pack-details {
    height: 150px;
  }
}

/* Extra large devices (min-width: 1200px) - 3 columns (or adjust if needed) */
@media (min-width: 1200px) {
  .item-align {
    width: 33.3333%; /* 3 columns */
  }
  .pack-details {
    height: 180px;
  }
}

@media (min-width: 200px) and (max-width: 375px) {
  .item-align {
    width: 100%; /* Full width for small screens */
  }
  .pack-details {
    height: 180px;
  }
}

@media (min-width: 376px) and (max-width: 414px) {
  .item-align {
    width: 100%; /* Full width for small screens */
  }
  .pack-details {
    height: 180px;
  }
}

@media (min-width: 415) and (max-width: 430) {
  .item-align {
    width: 100%; /* Full width for small screens */
  }
  .pack-details {
    height: 180px;
  }
}

@media (min-width: 431px) and (max-width: 540px) {
  .item-align {
    width: 100%; /* Full width for small screens */
  }
  .pack-details {
    height: 180px;
  }
}
