.quiz_stats_container {
    background: #FFF; /* Replace with the actual color */
    box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Adjust as needed */
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    margin-top: 5%;
    /* margin: 5%; */
    /* width: 70%; */
  }
  
  .progress_circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #EEE; /* Replace with the actual color */
    color: #333;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .quiz_details h3 {
    font-size: 0.9rem;
    color: #333;
  }
  
  .stats span {
    margin-right: 10px;
    font-size: 0.8rem;
  }
  
  .correct {
    color: #28a745;
  }
  
  .incorrect {
    color: #dc3545;
  }
  
  .unattempted {
    color: #6c757d;
  }
  
  .action_icons i {
    margin: 0 5px;
    display: block;
    /* Include actual icons or set background images */
  }
  
  .info_icon {
    /* style for the info icon */
    cursor: pointer;
  }
  
  .refresh_icon {
    /* style for the refresh icon */
    cursor: pointer;
  }
  