.audioPlayer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon {
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease; /* Add box-shadow transition */
}

.pause {
    box-shadow: 0 0 15px rgba(238, 59, 4, 0.3); /* Example box shadow for pause icon */
}

.play {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3); /* Example box shadow for play icon */
}

/* Optional: Hover effects */
.icon:hover {
    transform: scale(1.1); /* Example hover effect */
}
.playarea {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}
.playButton {
    background: none;
    border: 1px solid white;
    border-radius: 50% !important;
    padding: 30px;
    cursor: pointer;
    box-shadow: 0px 10px 20px rgba(245, 3, 4, 1.9);
    transition: background-color 0.6s, transform 0.6s;
}

.playButton:hover {
    background-color: white;
    color: #2C54EA;
    transform: scale(1.1);
    transition: cubic-bezier(0.075, 0.82, 0.165, 1);
}