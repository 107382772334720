.modal_container {
  text-align: center;
}

.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
}

.modal_content {
  background-color: #fefefe;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 600px; /* Maximum width of the modal */
  max-height: 75%;
  overflow: auto;
}

.close {
  color: #aaa;
  float: right;
  font-size: 20px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.circles_container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px; /* Adjust the space between circles */
}
.circle {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px; /* Circle size */
  height: 30px; /* Circle size */
  border-radius: 50%; /* This makes it round */
  font-weight: bold;
  color: white; /* Default color, will be overridden by dynamic styles */
  border: 3px solid transparent; /* Default border, will be overridden by dynamic styles */
}



.container {
  display: flex;
  flex-direction: column;
  gap: 30px; /* Space between each subcategory */
}
.subcategoryContainer {
  text-align: left;
}

.subcategoryTitle {
  margin: 0;
  font-size: 24px;
  color: #333;
}
.circlesContainer {
  display: flex;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px; /* Adjust the space between circles */
}


.pack-details {
  display: flex;
 
  background: #fff;
}

.pack-image {
  width: 165px;
  height: 165px;
}

.pack-info {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;
}

.pack-info p {
  color: gray;
  font-size: 14px;
  margin: 1px 0px;
}

.pack-row {
  margin-bottom: 10px;
}

.quizTable {
  width: 100%;
  border-collapse: collapse;
}

.quizTable th,
.quizTable td {
  width: 50%;
  border: 1px solid #ccc;
  text-align: center;
}